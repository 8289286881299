<template>
  <div class="goods-card">
    <div class="pic">
      <img :src="item.pic" />
    </div>
    <span v-if="type === 2 && badge" class="badge">福袋</span>
    <div class="info">
      <div class="title van-multi-ellipsis--l2">{{ item.title }}</div>
      <!--商品订单-->
      <div v-if="type === 1" class="type">
        {{ $global.attrs[item.pid - 1].label }}：{{ item.vname }}
      </div>
      <div class="price">￥{{ item.price }}</div>

      <div class="quantity">x{{ item.quantity ? item.quantity : 1 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoodsInOrder",
  props: {
    type: {
      type: Number,
      default: 1,
    },
    badge: {
      type: Boolean,
      default: false,
    },
    item: Object,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
