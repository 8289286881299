<template>
  <div class="prepay" v-if="order">
    <!--收货地址-->
    <van-cell-group
      v-if="order.address"
      inset
      class="addr-card"
      :border="false"
    >
      <van-cell
        center
        is-link
        icon="location"
        @click="showAddress(order.address)"
      >
        <template #default>
          <div class="city">
            {{ order.address.province }}
            {{ order.address.city }}
            {{ order.address.country }}
          </div>
          <div class="addr">{{ order.address.addr }}</div>
          <span class="people">{{ order.address.contact_name }} </span>
          <span class="mobile">{{ order.address.mobile }} </span>
        </template>
      </van-cell>
    </van-cell-group>
    <!--没有收货地址-->
    <span v-else @click="createAddress()" class="no-addr">
      <i class="add-icon-dashed" />
      添加收货地址
    </span>
    <!--/收货地址-->

    <!--商品预收-->
    <van-cell-group v-if="from !== 'cabinet'" class="items">
      <van-cell center v-for="item in order.carts" :key="item.cart_id">
        <template #default>
          <GoodsInOrder :item="item" />
        </template>
      </van-cell>
      <!-- <van-cell
        title="优惠券"
        value="请先选择收货地址"
        icon="coupon-o"
        is-link
        class="coupon"
        :to="{ name: 'Address', params: { mod: 'select' } }"
      />
      <van-cell
        class="coupon"
        title="优惠券"
        value="满150减10"
        icon="coupon-o"
        value-class="use"
        is-link
      /> -->
    </van-cell-group>
    <!--欧气福袋提货-->
    <van-cell-group v-else class="items">
      <van-cell center v-for="item in order.cabs" :key="item.cid">
        <template #default>
          <GoodsInOrder :item="item" :type="2" />
        </template>
      </van-cell>
    </van-cell-group>

    <van-cell-group class="money pull-bar cell-nowrap">
      <van-cell
        v-if="from !== 'cabinet'"
        title="商品"
        :value="`￥${order.price}`"
      />
      <van-cell title="运费">
        <template #default>
          <span v-if="from !== 'cabinet'" class="labelled">满500包邮</span>
          <span v-else class="labelled">满5件包邮</span>
          <span>￥{{ order.post_fee }}</span>
        </template>
      </van-cell>
      <van-cell
        title="合计"
        :value="`￥${order.payment}`"
        value-class="price"
      />
    </van-cell-group>

    <div class="paybar">
      <span class="text">
        应付金额：<span class="price">￥{{ order.payment }}</span>
      </span>
      <div class="btns">
        <van-button type="info" @click="toPay()">去支付</van-button>
      </div>
    </div>

    <!--我的地址，一定要把mod设为choice，否则无法选中-->
    <Address v-if="loadAddress" mod="choice" />
    <!--/我的地址-->

    <!--新建地址-->
    <van-popup
      v-model="newAddress"
      round
      get-container="body"
      closeable
      position="bottom"
      class="edit-addr"
    >
      <EditAddr :addrProp="addrProp" />
    </van-popup>
    <!--/新建地址-->
  </div>
</template>

<script>
import Bus from "@/api/bus.js";
import GoodsInOrder from "@/components/GoodsInOrder.vue";
import Address from "@/components/Address.vue";
import EditAddr from "@/components/EditAddr.vue";
export default {
  name: "Prepay",
  components: {
    GoodsInOrder,
    Address,
    EditAddr,
  },
  data() {
    return {
      from: null,
      order: null,
      ids: null,
      loadAddress: false,
      newAddress: false,
      addrProp: null,
    };
  },
  computed: {
    Preids() {
      return this.$store.state.Preids;
    },
  },
  created() {
    if (!this.Preids) {
      this.$router.go(-1);
      return false;
    }
    //判断是欧气福袋订单还是商品订单
    this.from = this.$route.params.from;
    this.getPrepay();
  },
  mounted() {
    //选择地址
    Bus.$off("setAddressOnbus");
    Bus.$on("setAddressOnbus", (item) => {
      this.order.address = item;
    });
    Bus.$off("reloadPrepayOnbus");
    Bus.$on("reloadPrepayOnbus", () => {
      this.getPrepay();
    });
    //直接新建地址成功
    Bus.$off("createSuccessOnBus");
    Bus.$on("createSuccessOnBus", (addr) => {
      this.$set(this.order, "address", addr);
      this.newAddress = false;
    });
  },
  methods: {
    createAddress() {
      this.addrProp = null;
      this.newAddress = true;
    },
    getPrepay() {
      this.ids = this.Preids.join();
      this.$ajax
        .post(
          `/order/prepay/${this.from}/`,
          { cart_ids: this.ids, cids: this.ids },
          "loading"
        )
        .then((res) => {
          this.order = res.data;
        })
        .catch((res) => {
          this.$router.go(-1);
          return false;
        });
    },
    showAddress(item) {
      this.loadAddress = true;
      this.$nextTick(() => {
        Bus.$emit("showAddressOnbus", item);
      });
    },
    toPay() {
      if (!this.order.address) {
        this.$toast("请先添加收货地址");
        return false;
      }
      let data = JSON.parse(JSON.stringify(this.order));
      delete data.carts; //不需要这两项
      delete data.address; //不需要这两项
      data.token = 1;
      data.address_id = this.order.address.address_id;
      data.cart_ids = this.ids;
      data.cids = this.ids;

      let keyurl = this.from === "cart" ? "item" : this.from;
      this.$ajax
        .post(`/order/trade/${keyurl}/`, data, "loading")
        .then((res) => {
          this.$store.commit("Preids", null); //清掉ids
          this.$router.push({ name: "Pay", params: { data: res.data } });
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      (to.name === "Cart" || to.name === "Cabinet") &&
      this.Preids &&
      this.order
    ) {
      this.$dialog
        .confirm({
          title: "还差一步，宝贝就到手啦！",
          message: "确定要取消支付吗？",
          closeOnPopstate: false, //点击取消后还能再弹窗
        })
        .then(() => {
          next();
          this.$store.commit("Preids", null); //清掉ids
        })
        .catch(() => {
          next(false);
        });
    } else {
      next();
      this.$store.commit("Preids", null); //清掉ids
    }
  },
};
</script>
